import { forwardRef } from "react";

const Section = forwardRef(
  ({ name, component: Component, childProps }, ref) => {
    return (
      <section
        id={name}
        ref={ref}
        className={`group/section${
          name !== "projects" ? " mb-16 md:mb-24 lg:mb-36" : ""
        }`}
      >
        <div className="sticky top-0 z-20 py-5 mb-4 bg-slate-900/75 backdrop-blur lg:hidden">
          <h2 className="text-sm font-bold text-slate-200 uppercase">{name}</h2>
        </div>
        <Component {...childProps} />
      </section>
    );
  }
);

export default Section;
