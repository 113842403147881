import { ArrowLink } from "../utils/renderSvg";
import ConditionalLink from "./conditionalLink";
import SectionRow from "./sectionRow";

const Projects = ({ data }) => {
  return (
    <>
      {data.map((proj, index) => (
        <SectionRow
          key={index}
          link={proj.link}
          className={`relative ${
            index < data.length - 1 ? "mb-12 " : ""
          }flex flex-col gap-4 sm:flex-row sm:gap-8 md:gap-4 group lg:hover:!opacity-100 lg:group-hover/section:opacity-50 transition-all lg:cursor-pointer`}
        >
          <div className="flex-1 sm:order-1 z-10">
            <ConditionalLink
              link={proj.link}
              className={
                "text-slate-200 font-medium leading-snug inline-block mb-2 group/link hover:text-teal-300 lg:group-hover:text-teal-300 focus-visible:text-teal-300 lg:group-focus-visible:text-teal-300"
              }
            >
              {proj.title}
              <ArrowLink />
            </ConditionalLink>
            <p className="text-sm leading-normal mb-2">{proj.summary}</p>
            <ul className="flex flex-wrap">
              {proj.skills.map((skill, index) => (
                <li key={index} className="mr-1.5 mt-2">
                  <div className="flex items-center rounded-full bg-teal-400/10 px-3 py-1 text-xs font-medium leading-5 text-teal-300">
                    {skill}
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="max-w-[200px] sm:w-[21%] md:w-1/4 z-10">
            <img
              src={require(`../assets/images/${proj.thumbnail}`)}
              alt="project thumbnail"
              width={200}
              height={48}
              className="rounded border-2 border-slate-200/10"
            />
          </div>
        </SectionRow>
      ))}
    </>
  );
};

export default Projects;
