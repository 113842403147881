import { ArrowLink } from "../utils/renderSvg";
import ConditionalLink from "./conditionalLink";
import SectionRow from "./sectionRow";

const Experience = ({ data }) => {
  return (
    <>
      {data.map((exp, index) => (
        <SectionRow
          key={index}
          link={exp?.company?.link}
          className="relative mb-12 sm:flex sm:gap-8 md:gap-4 group lg:hover:!opacity-100 lg:group-hover/section:opacity-50 transition-all lg:cursor-pointer"
        >
          <div className="text-xs uppercase mt-1 mb-2 text-slate-500 font-semibold max-w-[200px] sm:w-[21%] md:w-1/4 z-10">
            {exp.start} — {exp.end}
          </div>
          <div className="flex-1 z-10">
            {exp.company && (
              <ConditionalLink
                link={exp.company.link}
                className={
                  "text-slate-200 font-medium leading-snug group/link hover:text-teal-300 lg:group-hover:text-teal-300 focus-visible:text-teal-300 lg:group-focus-visible:text-teal-300"
                }
              >
                {exp.company.name}
                <ArrowLink />
              </ConditionalLink>
            )}
            <div className="text-slate-500 font-medium mb-2">
              {exp.designation}
            </div>
            <p className="text-sm leading-normal">{exp.summary}</p>
          </div>
        </SectionRow>
      ))}
    </>
  );
};

export default Experience;
