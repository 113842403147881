import { useState, useEffect, useContext } from "react";
import AppContext from "../AppContext";
import remToPx from "../utils/remToPx";
import { Github, LinkedIn } from "../utils/renderSvg";

const Header = () => {
  const { sections, screenWidth } = useContext(AppContext);
  const {
    basicData: { name, profession, headline, socialMedia },
  } = useContext(AppContext);
  const [activeLink, setActiveLink] = useState(sections[0].name);

  useEffect(() => {
    // minLaptop is true for minimum laptop screensize
    const minLaptop = screenWidth >= 1024;
    const handleHashChange = () => {
      const fragment = window.location.hash.substring(1);
      const fragmentIndex = sections.findIndex(
        (section) => section.name === fragment
      );
      if (fragmentIndex > -1) {
        window.scrollTo({
          behavior: "smooth",
          top:
            sections[fragmentIndex].ref?.current?.offsetTop -
            (minLaptop ? remToPx(6) : remToPx(4)),
        });
      }
    };
    const handleScrollChange = () => {
      const offset = remToPx(9);
      const scrollPosition = window.scrollY;
      for (const obj of sections) {
        const section = obj.ref?.current;
        const top = section?.offsetTop - offset; // Adjust this value to set the activation threshold
        const bottom = top + section?.offsetHeight + offset;
        if (scrollPosition >= top && scrollPosition < bottom) {
          setActiveLink(section?.id);
          break;
        }
      }
    };
    window.addEventListener("load", handleHashChange);
    window.addEventListener("hashchange", handleHashChange);
    if (minLaptop) window.addEventListener("scroll", handleScrollChange);
    return () => {
      window.removeEventListener("load", handleHashChange);
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, [sections, screenWidth]);

  const handleLinkClick = (e, section) => {
    e.preventDefault();
    window.history.pushState(null, null, `#${section.name}`);
    window.dispatchEvent(new Event("hashchange"));
  };

  return (
    <header className="lg:sticky top-0 lg:w-1/2 lg:max-h-screen lg:py-24 lg:flex lg:flex-col lg:justify-between">
      <div>
        <h1 className="text-4xl sm:text-5xl text-slate-200 font-bold tracking-tight mb-3">
          {name}
        </h1>
        <h2 className="text-lg sm:text-xl text-slate-200 font-medium mb-4">
          {profession}
        </h2>
        <p className="mb-8 lg:mb-16 max-w-xs">{headline}</p>
        <div className="hidden lg:flex lg:flex-col w-max">
          {sections.map((section, index) => {
            const { name } = section;
            return (
              <a
                key={index}
                href={"#" + name}
                className={`group${
                  activeLink === name ? " active" : ""
                } flex items-center py-3 cursor-pointer`}
                onClick={(e) => handleLinkClick(e, section)}
              >
                <span className="inline-block h-px mr-4 w-8 group-[.active]:w-16 group-hover:w-16 group-focus-visible:w-16 bg-slate-600 group-[.active]:bg-slate-200 group-hover:bg-slate-200 group-focus-visible:bg-slate-200 transition-all"></span>
                <span className="text-xs font-bold uppercase tracking-widest text-slate-500 group-[.active]:text-slate-200 group-hover:text-slate-200 group-focus-visible:text-slate-200">
                  {name}
                </span>
              </a>
            );
          })}
        </div>
      </div>
      <div className="flex gap-x-5">
        {socialMedia.map((media, index) => (
          <a
            key={index}
            href={media.url}
            target="_blank"
            rel="noreferrer noopener"
            className="hover:text-slate-200"
          >
            {media.platform === "github" && <Github />}
            {media.platform === "linkedin" && <LinkedIn />}
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header;
