import Section from "./section";
import { useContext } from "react";
import AppContext from "../AppContext";

const Main = () => {
  const { sections } = useContext(AppContext);
  return (
    <main className="pt-24 lg:py-24 lg:w-1/2">
      {sections.map((section, index) => {
        const { name, ref, component, data } = section;
        return (
          <Section
            key={index}
            ref={ref}
            name={name}
            component={component}
            childProps={{ data }}
          />
        );
      })}
    </main>
  );
};

export default Main;
