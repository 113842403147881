import "./App.css";
import AppContext from "./AppContext";
import data from "./data.json";
import Header from "./components/header";
import Main from "./components/main";
import SpecialUnderlay from "./components/specialUnderlay";
import About from "./components/about";
import Experience from "./components/experience";
import Projects from "./components/projects";
import { useEffect, useRef, useState } from "react";

function App() {
  const { basic, about, experience, projects } = data;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const SECTIONS = [
    { name: "about", ref: useRef(null), component: About, data: about },
    {
      name: "experience",
      ref: useRef(null),
      component: Experience,
      data: experience,
    },
    {
      name: "projects",
      ref: useRef(null),
      component: Projects,
      data: projects,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateCoordinates = (e) => {
    if (screenWidth >= 1024) setCoordinates({ x: e.clientX, y: e.clientY });
  };

  return (
    <AppContext.Provider
      value={{ sections: SECTIONS, basicData: basic, screenWidth }}
    >
      <div
        className="app-wrapper relative bg-slate-900 text-slate-400 antialiased selection:bg-teal-300 selection:text-teal-900"
        onClick={(e) => updateCoordinates(e)}
        onMouseMove={(e) => updateCoordinates(e)}
      >
        <SpecialUnderlay coordinates={coordinates} />
        <div className="xl:container mx-auto py-12 md:py-20 lg:py-0 px-6 md:px-12 lg:px-24 lg:flex lg:gap-4">
          <Header />
          <Main />
        </div>
      </div>
    </AppContext.Provider>
  );
}

export default App;
